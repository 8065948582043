@import './helperClass.css';

.custom-card-layout-margin {
  margin: 10px;
}

.custom-card-layout-padding {
  padding: 15px;
}

.custom-full-page-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
}

.while-bg-box {
  margin: 10px;
  background: white;
  padding: 10px;
  border-radius: 4px;
}

.bg-box {
  margin: 10px;
  border-radius: 4px;
}

.cus-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cus-table-header h3 {
  margin-bottom: 0px;
}

.cus-table-right-btn {
  display: flex;
  justify-content: end;
}
.cus-table-left-right-btn {
  display: flex;
  justify-content: space-between;
}

.cus-table-right-btn .export-generate-btn .ant-form-item {
  margin-bottom: 0px;
}

.cus-table-right-btn .bulk-action-btn {
  margin-left: 1rem;
}

.main-table-body-cus {
  padding: 0px 10px;
}

.table-loader {
  color: grey;
  font-size: 14px;
}

.d-d-title {
  display: flex;
  align-items: center;
}

.d-d-title {
  display: flex;
  align-items: center;
}

.details-badge .ant-badge-status-dot {
  height: 10px;
  width: 10px;
}
