.alloted-spots-main{
    background-color: #ffffff;
    margin: 30px 10px ;
    padding: 0px 0px 30px 0px;
    border:1px solid #D9D9D9 ;
}   
 .field-properties{
    margin-bottom: 10px !important;
}
.alloted-spots-text{
border-bottom: 1px solid #D9D9D9;
margin-top: 10px;
padding-bottom: 10px;
padding-left: 10px;
font-size: 16px;
margin-bottom: 20px;
}

.media-main{
    /* background-color: #ffffff; */
    margin: 30px 10px ;
    /* padding: 0px 0px 30px 0px; */
    /* border:1px solid #D9D9D9 ; */
}   