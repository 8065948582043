.create-campaign{
    padding: 20px 20px;
    }
    .company-field{
        display: flex;
    align-items: center;
    }
    .columns{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .button-form{
        margin-bottom: 0px !important;
        margin-top: 7px;
    }
    .save-continue-btn{
        margin-top: 25px;
    }
    .redirect-checkbox{
    margin-top: 40px;
    }