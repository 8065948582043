  .main-layout{
    position: relative;
  }
  .main-sidebar{
    position: absolute !important;
    left: 0;
    height: 100vh !important;
    z-index: 99;
    background-color: black;
  }

  .main-sidebar  .ant-menu-inline{
    border: none !important;
  }
  .offcanvasHeader{
    display: flex;
    justify-content: space-between;
  }
  .offcanvasCloseBtn{
    margin-right: 8px;
  }

  body:not(.body-offcanvas-class) .main-sidebar{
    transform: translateX(-100%);
  }
  
  body.body-offcanvas-class .main-sidebar{
    transform: translateX(0%);
    transition: 0.3s ease-in-out;
    z-index: 999;
  }
  body.body-offcanvas-class{
    overflow: hidden;
  }
  body.body-offcanvas-class .offCanvasLayer{
   display: block;
  }
  .offCanvasLayer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0, 0.5);
    z-index: 99;
    display: none;
  }
  .sideBarMenu{
    height: 100vh;
    overflow-y: auto;
    background-color: black !important;
    padding-bottom: 80px !important;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  body{
    background-color: #f0f2f5 !important;
  }