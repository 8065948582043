.tabs{
    display: flex;
    flex-direction: row;
    /* border:1px solid; */
  background-color: #FFFFFF;
  justify-content: space-around;
  padding:5px 10px;
  margin-bottom: 10px;
  }
  .completed-tab{
    /* background-color: #F0F2F5; */
    cursor: pointer;
  }
  .ongoing-tab{
    /* background-color: #F0F2F5; */
    cursor: pointer;
  }
  .highLighter{
    font-weight:bolder !important;
  }

.defaultMarginModal{
  margin-bottom: 20px;
}

.statistics-cards-data .custom-statistic .ant-statistic-title {
  font-size: 11px;
}
.statistics-cards-data .ant-statistic-content-value{
  font-size: 12px!important;
font-weight: 500!important;

}
.statistics-cards-data .ant-statistic-content-value .ant-statistic-content-value-int{
  font-size: 12px!important;
font-weight: 500!important;
}