input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

input[type=number] {
  -moz-appearance: textfield;
}

.media-section{
  margin-top: 40px;
}
.media-section .custom-upload-btn {
  display: flex;
  margin-bottom: 20px;
  border:1px solid black;
  padding: 10px;
  justify-content: center;
}
.media-section .custom-upload-btn label {
  cursor: pointer;
  width:100%;
  display:flex;
  justify-content:center;
}

.media-section .main{
    background-color: white;
    width:100%;
    padding:20px;
    display: flex;
    flex-wrap: wrap;
}
.media-section .title-text{
    background-color: white;
    width: 100%;
    border-bottom:1px solid #D9D9D9;
    padding: 10px 0px 10px 20px;
    font-size: 20px;
    font-weight: bold;

}
.media-section .video-block-box {
    padding-right: 10%;
    /* margin-bottom: 20px; */
  }
  .media-section .preview-btn {
    min-width: 168px;
  padding: 10px;
  }
 .media-section .btn-mr-rt {
    margin-right: 10px;
  }
  .video-btn-container{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
  }
  .video-btn{
    width:70%;
    padding: 20px;
    display: flex;
    align-items: center;
  }
  .video-dlt-btn{
    width:20%
  }
  .input-absolute{
    position:absolute;
    margin-top:5px;
    margin-left: -40px;
    background-color: white;
    width: 35px;
    padding-left: 10px;
  }
  .input-relative{
    position: relative;
  }
  .warningLayout{
    outline: 1px solid red;
    border: none !important;
  }
  .custom-upload-loader{
    
  }
  .file-format{
    font-size:11px;
    opacity: 0;
  }